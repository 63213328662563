import { RouteComponentProps } from '@reach/router';
import React from 'react';
import HomeTemplate from '../../templates/HomeTemplate';
import API from '../../utils/api';

const userReferral =
  'You got referred! Get 20% off Startup and Business plans for 3 months.';

interface ReferralHomeParams {
  referral: string;
}

type ReferralHomeProps = RouteComponentProps<ReferralHomeParams>;

interface ReferralHomeState {
  referralMessage: string;
}

export default class ReferralHome extends React.Component<
  ReferralHomeProps,
  ReferralHomeState
> {
  constructor(props: ReferralHomeProps) {
    super(props);
    this.state = { referralMessage: '' };
  }

  componentDidMount() {
    const { navigate, referral } = this.props;

    if (!navigate) {
      return;
    }

    if (!referral) {
      navigate('/', { replace: true });
    }

    API.request<{
      message: string;
    }>(
      `mutation verifyReferral($referral: String!) {
      message: verifyReferral(referral: $referral)
    }`,
      {
        referral,
      },
    )
      .then(({ message }) =>
        this.setState({ referralMessage: message || userReferral }),
      )
      .catch(() => navigate('/', { replace: true }));
  }

  render() {
    return (
      <HomeTemplate
        referral={this.props.referral}
        referralMessage={this.state.referralMessage}
      />
    );
  }
}
