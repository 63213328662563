import { Router } from '@reach/router';
import React from 'react';
import ReferralHome from '../../components/referral/ReferralHome';

export default () => (
  <Router>
    <ReferralHome path="/r" />
    <ReferralHome path="/r/:referral" />
  </Router>
);
